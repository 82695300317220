import { NavigationEntity } from './navigationEntity';

export enum FooterBackground {
    DarkBlue = 'Dark blue',
    Black = 'Black',
}

export interface FooterNavigation extends NavigationEntity {
    bottomContent: FooterBottomContent[];
    topContent: FooterTopContent[];
    copyRightText: string | null;
    socialMediaLinks: FooterSocialMediaLink[] | null;
    backgroundColor: FooterBackground | null;
}

export type NullableFooterNavigation = FooterNavigation | null;

export interface FooterBottomContent {
    rel?: string;
    href?: string;
    type: 'link';
    title?: string;
    isTargetToNewTab?: boolean;
}

export interface FooterTopContentBase {
    column: number;
    title: string;
}

export interface FooterTopContentLinkGroup extends FooterTopContentBase {
    type: 'sectionLink';
    links: FooterTopContentLink[];
}

export interface FooterTopContentImageLink extends FooterTopContentBase {
    type: 'imageLink';
    imagePath: string;
    imageAlt: string;
    href: string;
    isTargetToNewTab: boolean;
    ariaLabel?: string;
}

export type FooterTopContent = FooterTopContentLinkGroup | FooterTopContentImageLink;

export interface FooterSocialMediaLink {
    title: string;
    imagePath: string;
    href: string;
    type: 'socialMediaLink';
}

export interface FooterTopContentLink {
    href: string;
    isTargetToNewTab: boolean;
    title: string;
}

export type FooterContentType = 'topContent' | 'bottomContent' | 'socialMediaLinks';

export type FooterFormType = FooterTopContent | FooterBottomContent | FooterSocialMediaLink;

export type FooterTopContentType = FooterTopContent['type'];

export type FooterBottomContentType = FooterBottomContent['type'];

export type FooterSocialMediaLinkType = FooterSocialMediaLink['type'];

export type FooterContentTypeMap = {
    topContent: FooterTopContentType;
    bottomContent: FooterBottomContentType;
    socialMediaLinks: FooterSocialMediaLinkType;
};

export type FooterContentItemMap = {
    topContent: FooterTopContent;
    bottomContent: FooterBottomContent;
    socialMediaLinks: FooterSocialMediaLink;
};
